import React, { Fragment } from 'react';
import './App.css';

import {Helmet} from 'react-helmet';
import HomePage from './pages/homePage/HomePage';
import NavBar from './navBar/NavBar';
import { Route } from 'react-router-dom';
import Level_1 from './pages/level_1/Level_1';
import Level_2 from './pages/level_2/Level_2';
import AboutUs from './pages/aboutUs/AboutUs';



function App() {
  return (
    <Fragment>
        <Helmet>
           <meta charSet="UTF-8"/>
           <title>Implovate : Learn future-proof skills</title>
           <meta name='description' content='Learn future proof-skills to secure your future'/>
           <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <NavBar/>
        <Fragment>
          <Route exact path='/' component={HomePage} />
          <Route 
        path='/(.+)'
        render={() => (
          <Fragment>
     
          <Route path='/level-1' component={Level_1} />
          <Route path='/level-2' component={Level_2} />
          <Route path='/aboutUs' component={AboutUs} />

         
      
              </Fragment>
        )}/>
        </Fragment>

    
    </Fragment>
  );
}

export default App;
