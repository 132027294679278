import React, { Component, Fragment } from 'react'
import {Responsive, Segment} from 'semantic-ui-react'
import MobileNavBar from './MobileNavBar'
import DesktopNavBar from './DesktopNavBar'



class NavBar extends Component {
    render() {
        return (

        <Segment.Group size='mini'>
          
           <Responsive as={Fragment} {...Responsive.onlyComputer}>
               <DesktopNavBar/>
           </Responsive>


           <Responsive as={Fragment} {...Responsive.onlyMobile} >
                <MobileNavBar/>
            </Responsive>

            <Responsive as={Fragment} {...Responsive.onlyTablet} >
                <MobileNavBar/>
            </Responsive>


        </Segment.Group>   
        )
    }
}

export default NavBar