import React, { Component } from 'react'

class Level_1 extends Component {
    render() {
        return (
            <div>
                <h1>Level - 1</h1>
            </div>
        )
    }
}

export default Level_1