import React, { Component } from 'react'
import { Menu, Image, Dropdown, Button } from 'semantic-ui-react'
import logo from '../../src/images/website_logo_2.png'
import { NavLink } from 'react-router-dom'

class DesktopNavBar extends Component {
    render() {
        return (
         
                <Menu secondary  size='large'>
                <Menu.Item >
                   <Image size='small' src={logo} alt='' />
                </Menu.Item>

                <Menu.Item  as={NavLink} exact to='/' name='Home' />

               <Menu.Item as={NavLink} to='/aboutUs' name='About Us' />

               <Dropdown item text='Courses'>
               <Dropdown.Menu>
               <Dropdown.Item as={NavLink} to='/level-1' text='Level - 1' />
               <Dropdown.Item  text='Level - 2' />
               <Dropdown.Item  text='Level - 3' />
               <Dropdown.Item  text='Level - 4' />
               </Dropdown.Menu>
               </Dropdown>
               <Menu.Item as='a' name='Careers' />
               <Menu.Item as='a' name='Contact Us' />

               <Menu.Menu position='right'>
               <Menu.Item>
               <Button primary>My courses</Button>
               </Menu.Item>
                </Menu.Menu>


                </Menu>
           
        )
    }
}

export default DesktopNavBar